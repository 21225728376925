<template>
  <cw-page
    icon="person_outline"
    class="cw-application-start"
  >
    <template #hero-title>
      <translate>
        Welcome to the %{ product.brand } loan application.
      </translate>
    </template>
    <template #hero-text>
      <translate>
        It only takes about 5 minutes to complete the application and you will get the loan
        decision right away.
      </translate>
    </template>

    <validation-observer
      ref="observer"
      autocomplete="off"
      tag="v-form"
    >
      <v-row>
        <v-col id="form-element__ssn" cols="12" sm="6">
          <cw-text-field
            id="ssn"
            v-model="form.ssn"
            :hint="ssnHint"
            :label="ssnLabel"
            rules="required|seSsn"
            type="text"
            anonymize
            uppercase
            prevent-set-data
          />
        </v-col>
        <v-col id="form-element__phone" cols="12" sm="6">
          <cw-text-field
            id="phone"
            v-model="form.phone"
            :hint="phoneHint"
            :label="phoneLabel"
            rules="required|phone"
            type="tel"
            prevent-set-data
            anonymize
          />
        </v-col>
        <v-col
          cols="12"
          sm="6"
        >
          <cw-checkbox
            id="dataHandlingPermission"
            v-model="form.dataHandlingPermission"
            rules="required"
            hide-details
            prevent-set-data
            validate-empty
          >
            <template #label>
              <span data-test>
                <translate>
                  I agree to the storage of my information in the Service and the Company's Privacy
                  Policy.
                </translate>
              </span>
              <br><br>
              <a
                id="dataHandlingPermission--link"
                target="_blank"
                href="/pdf/privacy-policy-sv.pdf"
                @click.stop="$eventLogger.clickEvent($event);"
              >
                <translate>Privacy Policy</translate>
              </a>
            </template>
          </cw-checkbox>
        </v-col>
      </v-row>
    </validation-observer>

    <template #actions>
      <v-spacer/>
      <v-btn
        id="submit"
        :dark="!$wait.waiting('SUBMIT_FORM')"
        :disabled="$wait.waiting('SUBMIT_FORM')"
        :loading="$wait.waiting('SUBMIT_FORM')"
        color="purple-one darken-2"
        @click="
          validateForm();
          $eventLogger.clickEvent($event);
        "
      >
        <translate>Continue</translate>
      </v-btn>
    </template>
  </cw-page>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import { ValidationObserver } from 'vee-validate';
import { goToError } from '@shared/mixins';
import { DataLayer } from '@shared/services/dataLayer';

export default {
  name: 'CwApplicationStart',

  components: {
    'cw-checkbox': () => import('@shared/components/inputs/CwCheckbox'),
    'cw-text-field': () => import('@shared/components/inputs/CwTextField'),
    'validation-observer': ValidationObserver,
  },

  mixins: [goToError],

  data: () => ({
    form: {
      ssn: '',
      phone: '',
      dataHandlingPermission: '',
    },
  }),

  computed: {
    ...mapGetters({
      product: 'application/getProduct',
    }),

    formattedPhoneNumber() {
      return `+46${this.form.phone.replace(/\s/g, '').replace(/^\+46|^0/, '')}`;
    },

    /**
     * Gettext translations
     */
    phoneHint: vm => vm.$gettext('Enter a valid phone number. You will receive a verification code at the phone number you entered'),
    phoneLabel: vm => vm.$gettext('Phone number'),
    ssnHint: vm => vm.$gettext('Enter a valid identity number'),
    ssnLabel: vm => vm.$gettext('Identity number'),
  },

  methods: {
    ...mapActions({
      submitForm: 'application/submit',
    }),

    async validateForm() {
      const valid = await this.$refs.observer.validate();
      this.$dataLayer.PushToECommerce({
        params: {
          action: 'stepValidationClick',
        },
      });

      DataLayer.onValidationClick();

      if (!valid) {
        this.goToError();
        DataLayer.onValidationFail();

        return;
      }

      this.$dataLayer.PushToECommerce({
        params: {
          action: 'stepValidationSubmit',
        },
      });
      await this.submit();
    },

    async submit() {
      this.$wait.start('SUBMIT_FORM');

      const formParams = {
        ssn: this.form.ssn,
        phone: this.formattedPhoneNumber,
        dataHandlingPermission: this.form.dataHandlingPermission,
      };

      const routineParams = {
        uri: 'executeRoutine',
        routine: 'Start',
      };

      try {
        await this.submitForm({
          formParams,
          routineParams,
        });
      } catch (e) {
        this.$wait.end('SUBMIT_FORM');
      }
    },
  },
};
</script>
